import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import { Spin, Modal, Button } from "antd";
import AutoSizer from "react-virtualized-auto-sizer";
import TooltipContainer from "../components/UI/TooltipContainer";
import { unparse } from "papaparse";

function PlotContainer(props) {
    const api = props.useApi(props.useApiArgs);
    const isPopup = props.isPopup ? props.isPopup : true;
    const tooltipTitle = props.tooltipTitle ? props.tooltipTitle : "WIP";

    const [data, setData] = useState([]);
    const [layout, setLayout] = useState({});
    const [error, setError] = useState("");
    const [downloadData, setDownloadData] = useState([]);
    const [showCharts, setShowCharts] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        if (api.status === "fulfilled") {
            const parsedData = JSON.parse(api.data);
            setData(parsedData.data);
            setLayout(parsedData.layout);
            if (parsedData.downloadData) {
                console.log("Downloadable data: ", parsedData.downloadData);
                setDownloadData(parsedData.downloadData);
                console.log("Downloadable data: ", downloadData);
            }
            setShowCharts(false);
            setError("");
        } else if (api.status === "rejected") {
            setData([]);
            setLayout({});
            setShowCharts(true);
            setError("Error");
        } else if (api.status === "pending") {
            setData([]);
            setLayout({});
            setShowCharts(false);
            setError("");
        } else {
            setData([]);
            setLayout({});
            setShowCharts(false);
            setError("Error");
        }
    }, [api]);

    const handleOpenModal = () => {
        setIsModalVisible(true);
    };

    const handleCloseModal = () => {
        setIsModalVisible(false);
    };

    var icon = {
        'width': 1000,
        'path': 'm938 413l-188-125c0 37-17 71-44 94 64 38 107 107 107 187 0 121-98 219-219 219-121 0-219-98-219-219 0-61 25-117 66-156h-115c30 33 49 76 49 125 0 103-84 187-187 187s-188-84-188-187c0-57 26-107 65-141-38-22-65-62-65-109v-250c0-70 56-126 125-126h500c69 0 125 56 125 126l188-126c34 0 62 28 62 63v375c0 35-28 63-62 63z m-750 0c-69 0-125 56-125 125s56 125 125 125 125-56 125-125-56-125-125-125z m406-1c-87 0-157 70-157 157 0 86 70 156 157 156s156-70 156-156-70-157-156-157z',
        'ascent': 1000,
        'descent': -350,
    };

    // Custom modebar button
    const customModebarButton = {
        name: "Fullscreen",
        click: () => handleOpenModal(),
        icon: icon
    };

    return (
        <div
            style={{
                width: "100%",
                height: "100%",
            }}
        >
            {showCharts ? (
                <></>
            ) : (
                <>
                    {data.length === 0 && Object.keys(layout).length === 0 ? (
                        <Spin
                            style={{
                                margin: "5rem",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        />
                    ) : (
                        <AutoSizer>
                            {({ height, width }) => (
                                <div
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            margin: "0",
                                            width: width,
                                            height: height,
                                            flexDirection: "column",
                                            animation: "fadeIn 1.5s",
                                        }}
                                    >
                                        {tooltipTitle === "WIP" ? null : (
                                            <TooltipContainer
                                                title={tooltipTitle}
                                                style={{
                                                    alignSelf: "flex-end",
                                                    margin: "0",
                                                    marginRight: "0.3vw",
                                                }}
                                            />
                                        )}
                                        <Plot
                                            data={data}
                                            layout={layout}
                                            style={{
                                                width: width,
                                                height: height - 15,
                                            }}
                                            config={{
                                                responsive: true,
                                                displaylogo: false,
                                                modeBarButtonsToAdd: [customModebarButton],
                                                toImageButtonOptions: {
                                                    format: "png",
                                                    filename: "plot",
                                                    height: 900,
                                                    width: 1600,
                                                    scale: 1,
                                                },
                                                plotlyServerURL: 'https://chart-studio.plotly.com',
                                                displayModeBar: 'hover',
                                                showEditInChartStudio: true,
                                            }}
                                        />
                                        {downloadData.length > 0 ? (
                                            <Button
                                                type="primary"
                                                onClick={() => {
                                                    const csv = unparse(downloadData); // Converts array of objects to CSV
                                                    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
                                                    const url = URL.createObjectURL(blob);
                                                    const link = document.createElement("a");
                                                    link.href = url;
                                                    link.download = "data.csv";
                                                    document.body.appendChild(link);
                                                    link.click();
                                                    document.body.removeChild(link);
                                                }}
                                                style={{
                                                    alignSelf: "flex-end",
                                                    margin: "0",
                                                    marginRight: "0.3vw",
                                                }}
                                            >
                                                Download The Data Powering This Plot
                                            </Button>
                                        ) : null}

                                    </div>
                                </div>
                            )}
                        </AutoSizer>
                    )}
                </>
            )}
            <h2
                style={{
                    color: "red",
                    textAlign: "center",
                }}
            >
                {error}
            </h2>
            {isPopup && (
                <Modal
                    open={isModalVisible}
                    onCancel={handleCloseModal}
                    footer={null}
                    centered // Ensures the modal is centered
                    styles={{
                        body: {
                            padding: 0,
                            backgroundColor: "var(--tertiary-color)", // Use the correct background color
                            width: "88vw",
                            height: "88vh",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        },
                        content: {
                            padding: 0,
                            backgroundColor: "var(--tertiary-color)", // Use the correct background color
                            width: "92vw",
                            height: "92vh",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            // make the corners round
                            borderRadius: "20px",
                        },
                    }}
                    width="90vw" // Adjust width of the modal
                    height="90vh" // Adjust height of the modal
                >
                    <Plot
                        data={data}
                        layout={{
                            ...layout,
                            autosize: true,
                            width: null,
                            height: null,
                        }}
                        style={{ width: "100%", height: "100%", animation: "fadeIn 1.5s" }}
                        config={{
                            responsive: true,
                            displaylogo: false,
                            plotlyServerURL: 'https://chart-studio.plotly.com',
                            displayModeBar: 'hover',
                            showEditInChartStudio: true,
                        }}
                    />
                </Modal>
            )}
        </div>
    );
}

export default PlotContainer;