import { Button, Select } from "antd"
import {
    useGetUniqueCampaignsPlatformQuery, useGetConversionActionsForACampaignPlatformQuery,
    useGetCampaignConversionsTableQuery,
    useGetUnsetCampaignOptionsPlatformQuery, useUpdateCampaignConversionOptionMutation,
    useGetAllUniqueConversionActionsQuery, useGetDefaultConversionActionQuery, useSetDefaultConversionActionMutation
} from "../../redux/api/adsReporting";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TableContainer from "../TableContainer";
import TooltipContainer from "../../components/UI/TooltipContainer";

export default function SettingsDropdowns({ platform }) {

    const selectedClient = useSelector((state) => state.genericReporting.selectedClient);
    const selectedStartDate = useSelector((state) => state.genericReporting.startDate);
    const selectedEndDate = useSelector((state) => state.genericReporting.endDate);

    const [campaignOptions, setCampaignOptions] = useState([]);
    const [conversionOptions, setConversionOptions] = useState([]);
    const [selectedCampaign, setSelectedCampaign] = useState(null);
    const [selectedConversions, setSelectedConversions] = useState(null);
    const [defaultConversionActionsSelected, setDefaultConversionActionsSelected] = useState(null);

    const uniqueCampaigns = useGetUniqueCampaignsPlatformQuery({
        client: selectedClient,
        startDate: selectedStartDate,
        endDate: selectedEndDate,
        platform: platform
    });

    const uniqueConversionOptions = useGetConversionActionsForACampaignPlatformQuery({
        client: selectedClient,
        startDate: selectedStartDate,
        endDate: selectedEndDate,
        campaignName: selectedCampaign,
        platform: platform
    });

    const defaultConversionAction = useGetDefaultConversionActionQuery({
        client: selectedClient,
        platform: platform
    });

    const [updateDefaultConversionActions] = useSetDefaultConversionActionMutation();

    const allUniqueConversionActions = useGetAllUniqueConversionActionsQuery(
        {
            client: selectedClient,
            platform: platform
        }
    );

    const unsetCampaignOptions = useGetUnsetCampaignOptionsPlatformQuery(
        {
            client: selectedClient,
            startDate: selectedStartDate,
            endDate: selectedEndDate,
            platform: platform
        }
    );

    const [updateCampaignConversionOption] = useUpdateCampaignConversionOptionMutation();

    useEffect(() => {
        if (uniqueCampaigns.status === 'fulfilled') {
            setCampaignOptions(uniqueCampaigns.data.map(
                (campaign) => {
                    return {
                        label: campaign,
                        value: campaign
                    }
                }

            ));
        }
    }, [uniqueCampaigns]);


    useEffect(() => {
        if (uniqueConversionOptions.status === 'fulfilled') {
            setConversionOptions(uniqueConversionOptions.data.map(
                (conversion) => {
                    return {
                        label: conversion,
                        value: conversion
                    }
                }
            ));
        }
        else {
            setConversionOptions([]);
        }
    }, [uniqueConversionOptions]);

    useEffect(() => {
        if (defaultConversionAction.status === 'fulfilled') {
            setDefaultConversionActionsSelected(defaultConversionAction.data);
        }
    }, [defaultConversionAction]);


    return (
        <div>
            <TooltipContainer title='Settings'></TooltipContainer>
            <div>
                <h3>Set a default conversion action</h3>
                <p>Set a default conversion action that will automatically used for campaigns that don't have a conversion action set</p>
                <p>Current default conversion action(s) set to: {Array.isArray(defaultConversionAction.data) ? defaultConversionAction.data.join(', ') : ''}</p>
                <div>
                    <Select
                        showSearch={true}
                        style={{ width: '100%' }}
                        placeholder="Please select"
                        options={allUniqueConversionActions.data ? allUniqueConversionActions.data.map(
                            (conversion) => {
                                return {
                                    label: conversion,
                                    value: conversion
                                }
                            }
                        ) : []}
                        onChange={
                            (value) => {
                                setDefaultConversionActionsSelected(value);
                            }
                        }
                        mode='multiple'
                        value={defaultConversionActionsSelected}
                    />
                    <Button
                        onClick={
                            () => {
                                updateDefaultConversionActions({
                                    client: selectedClient,
                                    platform: platform,
                                    conversionActions: defaultConversionActionsSelected
                                })
                            }
                        }
                        style={{
                            'marginTop': '1vh'
                        }}
                        type='primary'>Save</Button>
                </div>
            </div>
            <br></br>
            <div style={{
                'display': 'flex',
                'flexDirection': 'row'
            }}>
                <div style={{
                    marginRight: '2vw'
                }}>
                    <TableContainer useApi={useGetCampaignConversionsTableQuery} apiParams={{
                        client: selectedClient,
                        platform: platform,
                    }} width="28vw" />
                </div>
                <div>
                    <h3>These campaigns do not have a set conversion goal for the time frame selected, the default conversion action selected above will be used for them.:</h3>
                    <ul>
                        {unsetCampaignOptions.data ? unsetCampaignOptions.data.map((campaign) => {
                            return <li>{campaign}</li>
                        }) : <></>}
                    </ul>
                </div>
            </div>
            <h2>Campaigns</h2>
            <Select
                showSearch={true}
                style={{ width: '100%' }}
                placeholder="Please select"
                options={campaignOptions}
                onChange={(value) => {
                    setSelectedCampaign(value);
                    setSelectedConversions(null);
                }
                }
            />
            <h2>Conversions</h2>
            <Select
                showSearch={true}
                style={{ width: '100%' }}
                placeholder="Please select"
                options={conversionOptions}
                onChange={(value) => setSelectedConversions(value)}
                value={selectedConversions}
                mode='multiple'
            />
            <Button onClick={
                () => {
                    updateCampaignConversionOption({
                        client: selectedClient,
                        campaignName: selectedCampaign,
                        conversionActions: selectedConversions,
                        platform: platform
                    })
                }
            } style={{
                'marginTop': '1vh'
            }} type='primary'>Submit</Button>
        </div>
    )

}