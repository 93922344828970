import './Header.css'
import logo from '../../static/img/creamlogo.png'
import React, { useState, useEffect } from 'react'
import { Menu } from 'antd'
import { useGetAllTabsUserHasAccessToQuery } from '../../redux/api/user'
import LoginIcon from './LoginIcon'

export default function Header() {

    const currentUrlPath = window.location.pathname

    const [menuOpen, setMenuOpen] = useState(false);
    const allowedTabs = useGetAllTabsUserHasAccessToQuery();

    const [dashboards, setDashboards] = useState([]);
    const [tabsInline, setTabs] = useState([]);

    const [mediaDashboards, setMediaDashboards] = useState([]);

    const [selectedKeys, setSelectedKeys] = useState([currentUrlPath]);

    useEffect(() => {

        if (allowedTabs.isSuccess) {
            var tabs = allowedTabs.data;
            tabs = [...new Set(tabs)];
            setDashboards([])
            setTabs([])
            var tmpDashboards = [];
            var tmpTabs = [];
            var tmpMediaDashboards = [];
            for (let i = 0; i < tabs.length; i++) {
                const tab = tabs[i];
                if (tab == 'executive_summary') {

                    tmpDashboards.push(<Menu.Item style={{
                        animation: 'fadeIn 1s'
                    }} key="/executive-summary"><a href='/executive-summary'>Executive Summary</a></Menu.Item>);
                } else if (tab == 'site_analytics') {

                    tmpDashboards.push(<Menu.Item style={{
                        animation: 'fadeIn 1s'
                    }} key="/site-analytics"><a href='/site-analytics'>Site Analytics</a></Menu.Item>);
                } else if (tab == 'compass') {
                    tmpTabs.push(<Menu.Item style={{
                        animation: 'fadeIn 1s'
                    }} key="/compass"><a href='/compass'>Compass</a></Menu.Item>);
                } else if (tab == 'ltr') {
                    tmpDashboards.push(<Menu.Item style={{
                        animation: 'fadeIn 1s'
                    }} key="/customer-analytics"><a href='/customer-analytics'>Customer Analytics</a></Menu.Item>);
                } else if (tab == 'paid_media') {
                    tmpMediaDashboards.push(<Menu.Item style={{
                        animation: 'fadeIn 1s'
                    }} key="/paid-media"><a href='/paid-media'>Paid Media</a></Menu.Item>);
                }
                else if (tab == 'klaviyo') {
                    tmpDashboards.push(<Menu.Item style={{
                        animation: 'fadeIn 1s'
                    }} key="/klaviyo-acquisition"><a href='/klaviyo-acquisition'>Klaviyo Acquisition</a></Menu.Item>);
                }
                else if (tab == 'influencer') {
                    tmpTabs.push(<Menu.Item style={{
                        animation: 'fadeIn 1s'
                    }} key="/influencer"><a href='/influencer'>Influencer</a></Menu.Item>);
                }
                else if (tab == 'bulk_upload') {
                    tmpTabs.push(<Menu.Item style={{
                        animation: 'fadeIn 1s'
                    }} key="/bulk-upload"><a href='/bulk-upload'>Bulk Upload</a></Menu.Item>);
                }
                else if (tab == 'whitelisted_media') {
                    tmpMediaDashboards.push(<Menu.Item style={{
                        animation: 'fadeIn 1s'
                    }} key="/whitelisted-media-comparison"><a href='/whitelisted-media-comparison'>Whitelisted Media Comparison</a></Menu.Item>);
                }
                else if (
                    tab == 'budget_planner'
                ) {
                    tmpMediaDashboards.push(<Menu.Item style={{
                        animation: 'fadeIn 1s'
                    }} key="/budget-planner"><a href='/budget-planner'>Budget Planner</a></Menu.Item>);
                }
            }
            setDashboards(tmpDashboards);
            setTabs(tmpTabs);
            setMediaDashboards(tmpMediaDashboards);
        }
        if (allowedTabs.isError) {
            setTimeout(() => {
                allowedTabs.refetch();
            }, 1000);
        }
    }
        , [allowedTabs]);

    const handleMenuClick = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <div id='headerdiv'>
            <div style={{
                'display': 'flex',
                'width': '100%'
            }}>
                <a href='/'>
                    <img style={{
                        marginLeft: '1vw',
                        marginTop: '2.8vh',
                        marginRight: '1vw',
                        width: '20vw',
                        height: 'auto',
                        alignContent: 'left',
                        alignSelf: 'left',
                        alignItems: 'left',
                    }} id='logoimage' src={logo} alt="loading..." />
                </a>
                <div id='headerdiv-inside'>
                    {allowedTabs.data ?
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: 'auto',
                            minWidth: '40vw',
                            border: '1px solid #01b375',
                            borderRadius: '10px',
                            marginTop: '1.8vh',
                            marginBottom: '1.8vh',
                            paddingLeft: '0.0vw',
                            paddingRight: '1.8vw',
                            marginRight: '1.5vw',
                        }}>
                            <Menu mode="horizontal" selectedKeys={[currentUrlPath]} style={{
                                'display': 'flex',
                                'justifyContent': 'space-around',
                                'alignItems': 'center',
                                'backgroundColor': 'transparent',
                                'width': '100%',
                            }}>
                                <Menu.SubMenu title="Growth Dashboards" style={{
                                    animation: 'fadeIn 1s'
                                }}>
                                    {dashboards}
                                </Menu.SubMenu>
                                <Menu.SubMenu style={{
                                    animation: 'fadeIn 1s'
                                }} title="Paid Media Dashboards">
                                    {mediaDashboards}
                                </Menu.SubMenu>
                                {tabsInline}
                                <Menu.Item style={{
                                    animation: 'fadeIn 1s'
                                }} key="/settings">
                                    <a href='/settings'>Settings</a>
                                </Menu.Item>
                            </Menu>
                            <LoginIcon></LoginIcon>
                        </div> : <div></div>}
                </div>
            </div>
        </div>
    )
}