/* eslint-disable eqeqeq */
import { BrowserRouter, Routes, Route } from "react-router-dom";
import RequireAuth from "./components/RequireAuth";
import Layout from "./components/UI/Layout";
import HomePage from "./components/HomePage";
import Compass from "./components/Compass";
import SiteAnalyticsPage from "./components/SiteAnalyticsPage";
import ExecutiveSummaryPage from "./components/ExecutiveSummary/ExecutiveSummaryPage";
import PaidMediaPage from "./components/Paidmedia/PaidMediaPage";
import KlaviyoAcquisitionPage from "./components/KlaviyoAcquisitionPage";
import { useGetAllTabsUserHasAccessToQuery } from "./redux/api/user";
import { useEffect, useState } from "react";
import RetentionPage from "./components/RetentionPage";
import SettingsPage from "./components/Settings/SettingsPage";
import Influencer from "./components/Influencer/Influencer";
import BulkUpload from "./components/BulkUpload/BulkUpload";
import WhitelistedMediaComparison from "./components/WhitelistedMediaComparison/WhitelistedMediaComparison";
import BudgetPlannerPage from "./components/BudgetPlanner/BudgetPlannerPage";

function App() {

  const allowedTabs = useGetAllTabsUserHasAccessToQuery();
  const [routes, setRoutes] = useState([]);

  useEffect(() => {
    if (allowedTabs.isError) {
      allowedTabs.refetch()
    }
    if (allowedTabs.isSuccess) {
      var tmpRouters = [];
      for (var i = 0; i <= allowedTabs.data.length; i++) {
        const tab = allowedTabs.data[i];
        if (tab == 'executive_summary') {
          tmpRouters.push(<Route path="/executive-summary" element={<ExecutiveSummaryPage />} />);
        } else if (tab == 'site_analytics') {
          tmpRouters.push(<Route path="/site-analytics" element={<SiteAnalyticsPage />} />);
        } else if (tab == 'compass') {
          tmpRouters.push(<Route path="/compass" element={<Compass />} />);
        } else if (tab == 'ltr') {
          tmpRouters.push(<Route path="/customer-analytics" element={<RetentionPage />} />);
        }
        else if (tab == 'paid_media') {
          tmpRouters.push(<Route path="/paid-media" element={<PaidMediaPage />} />);
        }
        else if (tab == 'klaviyo') {
          tmpRouters.push(<Route path="/klaviyo-acquisition" element={<KlaviyoAcquisitionPage />} />);
        }
        else if (tab == 'influencer') {
          tmpRouters.push(<Route path="/influencer" element={<Influencer></Influencer>} />);
        }
        else if (
          tab == 'bulk_upload'
        ) {
          tmpRouters.push(<Route path="/bulk-upload" element={<BulkUpload />} />);
        }
        else if (tab == 'whitelisted_media') {
          tmpRouters.push(
            <Route path="/whitelisted-media-comparison" element={<WhitelistedMediaComparison />} />
          );
        }
        else if (
          tab == 'budget_planner'
        ) {
          tmpRouters.push(<Route path="/budget-planner" element={<BudgetPlannerPage />} />);
        }
      }
      setRoutes(tmpRouters);
    }
  },
    [allowedTabs]);

  return (
    <Layout>
      <RequireAuth>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={
              <HomePage></HomePage>}>
            </Route>
            <Route path="/settings" element={
              <SettingsPage></SettingsPage>}>
            </Route>
            {routes}
          </Routes>
        </BrowserRouter>
      </RequireAuth>
    </Layout>
  );
}

export default App;